/* .banner {
  background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 1) 60%,
      rgba(0, 0, 0, 0.8)
    ),
    url(../../imgs//projBanners/bg.jpg);
  background-position: center center;
  background-size: cover;
} */

.lg-react-element {
  --bs-gutter-x: 1rem;
  --bs-gutter-y: 1rem;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.lg-react-element > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 50%;
  margin-inline: auto;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}



@media screen and (width < 992px) {
    .lg-react-element > * {
        max-width: 100% !important;
    }
}
