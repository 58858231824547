.borderGrey {
  border: 1px solid var(--greyColor) !important;
}
.borderBottomGrey {
  border-bottom: 0.5px solid var(--greyColor) !important;
}
.borderGrey:hover:not(.cardItem) {
  cursor: pointer;
  background-color: #1f1e1f;
}

/* !Searchbar */
.input-group,
.input-group-text,
.form-control {
  border-color: var(--greyColor) !important;
  color: white !important;
  background-color: transparent !important;
}
.form-control::placeholder {
  color: var(--greyColor) !important;
}
.form-control:focus {
  border: 1px var(--focusedColor) solid !important;
  background-color: #1f1e1f !important;
  box-shadow: 0 0 0 0 var(--focusedColor) !important;
}
/* !Recommended */
.recommended .recItem {
  cursor: pointer;
}

/* !cards */
.projects .cardItem {
  cursor: pointer;
  background-color: var(--secBgColor);
}

.projects .projLink i {
  transition: 400ms;
}
.projects .projLink:hover i {
  color: var(--mainColor) !important;
}

 .MuiButtonBase-root:not(.Mui-selected) {
   color: white !important; 
} 

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected , .css-1q2h7u5.Mui-selected{
  color: var(--mainColor) !important;
}
.css-1aquho2-MuiTabs-indicator, .css-ttwr4n{
  background-color: var(--mainColor) !important;
}