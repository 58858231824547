body {
  /* background-color: #191919 !important; */
  background-image: url("../../imgs/bg.png");
  color: white !important;
}

.socialmedia {
  background-color: #0d0d0d;
  padding: 18px;
  position: fixed;
  top: 18px;
  right: 15px;
  z-index: 55;
  border: 1px solid white;
  border-radius: 15px;
}
.socialmedia::before {
  content: "";
  position: absolute;
  bottom: 101%;
  left: 50%;
  translate: -50% 0;
  width: 2px;
  height: 100%;
  border-radius: 15px;
  z-index: -4;
  background-color: var(--mainColor);
}
 .socialItems i {
  transition: color 500ms !important;
  cursor: pointer !important;
}
 .socialItems i:hover {
  color: var(--mainColor) !important;
}
.followMe {
  cursor: grabbing;
  position: absolute;
  bottom: -15px;
  left: 50%;
  translate: -50% 0;
  rotate: 90deg;
  width: 60px;
  padding: 3px;
  font-size: 11px;
  background-color: var(--mainColor);
  user-select: none;
}

.css-ptiqhd-MuiSvgIcon-root,
.css-1k33q06 {
  color: rgb(102, 60, 0);
}
