:root {
  --greyColor: #696869;
  --focusedColor: #808081;
  --mainColor: #dd6f08;
  --secBgColor: #1f1f1f;
  /* --mainColor: rgb(255, 162, 115); */
}

.primaryBg {
  background-color: #181918 !important;
}
.secBg {
  /* background-color: #282828 !important; */
  background-color: #262626 !important;
}
.opensansFont,
.title {
  font-family: "Open Sans", sans-serif;
}
.mutedText {
  color: #737179 !important;
}
.primaryText {
  font-family: "Open Sans", sans-serif;
  color: var(--mainColor);
}

.cursorPointer {
  cursor: pointer;
}
.cursorGrab {
  cursor: grab;
}

.w-fit {
  width: fit-content !important;
}
.fs-7 {
  font-size: 14px !important;
}

button:disabled {
  cursor: not-allowed !important;
}

/* Fonts */
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:opsz,wght@6..12,200&display=swap");
@font-face {
  font-family: "MyFont";
  src: local("MyFont"),
    url(../public/fonts/Satoshi_Complete/Fonts/OTF/Satoshi-Black.otf)
      format("woff");
}

.sunFont {
  font-family: "Nunito Sans", sans-serif;
}

/* Scroll */
/* !Scroll Design */
* {
  scrollbar-width: auto;
  scrollbar-color: #15181d;
}
*::-webkit-scrollbar {
  width: 7px;
}
*::-webkit-scrollbar-track {
  background: #343a41;
}
*::-webkit-scrollbar-thumb {
  background-color: var(--mainColor);
  border-radius: 10px;
}
