.navbar {
  top: 18px;
}
.navbar .container {
  background-color: #0d0d0d;
  width: 50% !important;
  position: relative !important;
  z-index: 59999 !important;
}
.navbar .container::before {
  content: "";
  position: absolute;
  bottom: 101%;
  right: 90%;
  width: 2px;
  height: 55px;
  border-radius: 15px;
  z-index: -4 !important;
  background-color: var(--mainColor);
}
.navbar .container::after {
  content: "";
  position: absolute;
  bottom: 101%;
  left: 90%;
  width: 2px;
  height: 55px;
  border-radius: 15px;
  z-index: -4;
  background-color: var(--mainColor);
}
.nav-item .nav-link {
  color: white !important;
  font-size: 14px;
}
.nav-item .nav-link.active,
.nav-item .nav-link:hover {
  color: var(--mainColor) !important;
  transition: color 1s;
}

@media screen and (width <= 560px) {
  .navbar .container {
    width: 80% !important;
  }
}

@media screen and (width <= 400px) {
  .navbar .container {
    width: 90% !important;
  }
}

@media screen and (width < 992px) {
  .socialmedia {
    display: none;
  }
}

@media screen and (width > 1200px) {
  .navbar .container {
    width: 40% !important;
  }
}

@media screen and (width >= 1400px) {
  .navbar .container {
    width: 35% !important;
  }
}
